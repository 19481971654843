import axios from 'axios';

import {
	GET_PROGRAMS_REQUEST,
	GET_PROGRAMS_SUCCESS,
	GET_PROGRAMS_FAIL,
	UPDATE_ORDER_STATE,
	GET_RESPONSE_STATUS,
	EMPTY_ORDERS,
} from '../constants/getOrderActions';

export const programRequest = () => async (dispatch, getState) => {
	const {
		loginDetails: { userInfo },
	} = getState();

	try {
		dispatch({
			type: GET_PROGRAMS_REQUEST,
		});

		const requestPayload = {
			firstName: userInfo.firstName,
			lastName: userInfo.lastName,
			rewardsId: userInfo.rewardsId,
		};

		const { data, status } = await axios.post(
			process.env.REACT_APP_REDEEM_URL,

			requestPayload
		);

		localStorage.setItem('orders-' + userInfo.id, JSON.stringify(data));
		dispatch({
			type: GET_RESPONSE_STATUS,
			payload: status,
		});

		dispatch({
			type: GET_PROGRAMS_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_PROGRAMS_FAIL,
		});
	}
};

export const updateOrderState = (ordersData) => (dispatch) => {
	dispatch({
		type: UPDATE_ORDER_STATE,
		payload: ordersData,
	});
};
export const emptyOrders = () => (dispatch) => {
	dispatch({
		type: EMPTY_ORDERS,
		payload: [],
	});
};
