import React, { useEffect, useState } from 'react';
import { Button, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import styles from '../styles/FormContainer.css';
import { programRequest } from '../actions/orderAction';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Loader from '../components/Loader';
import PastRedeemTable from '../components/PastRedeemTable';
import PastRedeemPendingTable from '../components/PastRedeemPendingTable';
import {
	loginRequest,
	logOut,
	updateState,
	emptyUpdateState,
} from '../actions/userAction';
import OverLay from '../components/OverLay';
import IdleTimer from '../components/IdleTimer';

const RewardScreen = () => {
	const { id } = useParams();

	const dispatch = useDispatch();
	const loginDetails = useSelector((state) => state.loginDetails);

	const [redirect, setRedirect] = useState(false);
	const [show, setShow] = useState(false);
	const [orders, setOrders] = useState();

	const { loading, userInfo } = loginDetails;
	const history = useHistory();

	useEffect(() => {
		//let token = JSON.parse(localStorage.getItem(id));
		let rewardsId = JSON.parse(localStorage.getItem('reward-' + id));

		if (rewardsId && !redirect) {
			dispatch(updateState(rewardsId));
		}

		const ordersInfo = JSON.parse(localStorage.getItem('orders-' + id));
		if (!orders) {
			setOrders(ordersInfo);
		} else {
			setOrders('');
		}
	}, []);

	useEffect(() => {
		if (userInfo && userInfo.rewardsId && !redirect) {
			localStorage.setItem('reward-' + userInfo.id, JSON.stringify(userInfo));
		}

		if (orders && orders.length > 0) {
			debugger;

			localStorage.removeItem('orders-' + id);
			localStorage.removeItem('ex-claimrewards');

			setOrders('');

			dispatch(loginRequest(userInfo.rewardsId, userInfo.dob, userInfo.id));
		}

		if (!show) {
			//const getUserInfo = JSON.parse(localStorage.getItem('reward-' + id));

			if (id) {
				try {
					setTimeout(() => {
						setShow(true);
					}, 900000); //900000 milliseconds
				} catch (error) {
					console.log('error', error);
				}
			}
		}

		/* IdelTimer takes timeout, OnTimeout, onExpired as input and extends the users time if he or she on mutiple tabs
		and redirects to home page if user is not active for 30 minutes  and pass timeout as seconds*/

		if (!redirect) {
			const getUserInfo = JSON.parse(localStorage.getItem('reward-' + id));

			if (id === ((userInfo && userInfo.id) || getUserInfo.id)) {
				const timer = new IdleTimer({
					timeout: 1800, // 1800 this in seconds
					userId: id,

					onTimeout: () => {
						localStorage.removeItem('reward-' + userInfo.id);
						localStorage.removeItem('ex-' + id);
						setRedirect(true);
						dispatch(logOut());
						dispatch(emptyUpdateState());

						history.push('./');
					},
					onExpired: () => {},
				});
				return () => {
					timer.cleanUp();
				};
			}
		}

		if (!userInfo) {
			history.push({ pathname: '/' });
		}
	}, [dispatch, userInfo, history, redirect, show, id, orders, loginDetails]);

	/* onClaimSubmit redirects to orderScreen when user clicks on 
	Click Here to Claim Your Gift Card(s) */

	const onClaimSubmit = (e) => {
		e.preventDefault();
		localStorage.removeItem('ex-' + id);
		history.push(`/orderscreen/${userInfo.id}`);
		dispatch(programRequest());
	};

	/* handleClose closes the OverLay if user clicks on yes button */
	const handleClose = () => {
		setShow(false);
	};

	/* handleLogout redirects to the  home page and  logOut the user
	  if users clicks on no button */

	const handleLogout = () => {
		if (userInfo.id === id) {
			localStorage.removeItem('reward-' + id);
			localStorage.removeItem('ex-' + id);
		}
		dispatch(logOut());
		history.push('/');

		setShow(false);
	};

	return (
		<div>
			<Header id={id} />
			{loading && <Loader />}
			<div>
				{!loading && (
					<div className={styles.headings}>
						<h3 className="welcome-title">Welcome, {userInfo?.firstName} {userInfo?.lastName}!</h3>
					</div>
				)}
				<br />
				<div>
					{show && (
						<OverLay
							show={show}
							handleClose={handleClose}
							handleLogout={handleLogout}
						/>
					)}
				</div>
				<div className={styles.headings}>
					<div>
						{userInfo?.giftcardList?.length > 0 && (
							<div>You have the following new redemptions: </div>
						)}
					</div><br />
					{userInfo?.giftcardList?.length > 0 &&
						userInfo?.giftcardList.map((giftCard) => (
							<div key={giftCard}>
								<h3>
									${giftCard.price} {giftCard.promotionName} gift card
								</h3>
							</div>
						))}
				</div>
				<div>
					{userInfo && !userInfo.giftcardList.length && (
						<Alert variant="warning">
							No new redemptions at this time.<br />
							New redemptions become available on <strong>Sundays following
							the kiosk swipe</strong><br />
							and must be claimed within the redemption period
							dates listed on your voucher.<br /><br />
							Looking for prior redemptions?
							Please scroll <strong>&#8595;</strong>down<strong>&#8595;</strong> to <strong>Past Redemptions</strong> below.
						</Alert>
					)}
				</div>
				<div>
					{userInfo?.giftcardList.length > 0 && (
						<Button
							variant="primary"
							type="submit"
							onClick={(e) => onClaimSubmit(e)}
							className="gc-claim-button"
						>
							Click Here to Claim Your Gift Card(s)
						</Button>
					)}
				</div>
				<div>
					<div>
						{userInfo?.giftcardListPending.length ? (
							<PastRedeemPendingTable userInfo={userInfo} />
						) : (
							''
						)}
					</div>
				</div>
				
			</div>
			<div>
				{userInfo?.giftcardListHistory.length ? (
					<PastRedeemTable userInfo={userInfo} />
				) : (
					''
				)}
			</div>
			<Footer />
		</div>
	);
};

export default RewardScreen;