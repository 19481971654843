import React from 'react';
import { Nav, Button } from 'react-bootstrap';

const PastRedeemPendingTable = ({ userInfo }) => {
	const { giftcardListPending } = userInfo;

	return (
		<div className="pastRedeem">
			<h3>Past Pending Redemptions:</h3>
			<table class="table">
				<thead class="tablehead">
					<tr>
						{/* <th scope="col">Date</th> */}
						<th scope="col">Swipe Date</th>
						<th scope="col">Order ID</th>
						<th scope="col">Name</th>
						<th scope="col">Amount</th>
						<th scope="col">Link</th>
					</tr>
				</thead>

				<tbody>
					{giftcardListPending?.map((giftCard) => (
						<tr key={giftCard.sku}>
							{/* <td>{giftCard.redeemDate}</td> */}
							<td>{giftCard.swipeDate}</td>
							<td>{giftCard.alderOrderId}</td>
							<td>{giftCard.promotionName}</td>
							<td> ${giftCard.price}</td>
							
							{giftCard.url === 'PENDING' ? 
								(
									<td>PENDING</td>
								) :
								(
									<td>
								<Nav.Item>
									<Nav.Link href={giftCard.url} target="_blank">
										{giftCard.url}
									</Nav.Link>
								</Nav.Item>
							</td>
								)}
							
							
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default PastRedeemPendingTable;
