import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from '../styles/FormContainer.css';

const Order = ({ order, loading }) => {
	return (
		<div>
			<Card className="my-3 p-3 rounded">
				<Card.Title as="div">
					<div>
						You have redeemed your ${order.InitialBalance} {order.Brand} gift card
					</div>
				</Card.Title>

				{order.CertificateLink === 'PENDING' ? (
					<h4>The system is taking a little longer than usual to retrieve your gift card link.
					Please wait 10-15 minutes, then log back in. Your gift card will be listed under
					the Past Redemptions section. If it still does not appear after logging back in,
					please contact <a href="mailto:cs@sigmarsolutions.com">cs@sigmarsolutions.com</a>
					with your Rewards #, full name, and gift card information.</h4>
				) : (
					<Card.Title as="div">
						<a
							href={order.CertificateLink}
							target="_blank"
							class="click-here-card-link"
						>
							CLICK HERE FOR YOUR GIFT CARD{' '}
						</a>
					</Card.Title>
				)}
			</Card>{' '}
		</div>
	);
};

export default Order;
