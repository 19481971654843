import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button } from 'react-bootstrap';

import FormContainer from '../components/FormContainer';
import { loginRequest, updatedValues } from '../actions/userAction';
import InputMask from 'react-input-mask';
import '../styles/FormContainer.css';
import logo from '../static/HRSC-Logo-2022-1280.png';
import Loader from '../components/Loader';
import CryptoJS from 'crypto-js';

const LoginScreen = ({ history }) => {
	const [dob, setDob] = useState('');
	const [rewardId, setRewardId] = useState('');

	const dispatch = useDispatch();
	const loginDetails = useSelector((state) => state.loginDetails);
	const { loading,error } = loginDetails;

	useEffect(() => {
		if (loginDetails.userInfo && !loginDetails.error) {
			const { userInfo } = loginDetails;

			history.push(`./claimrewards/${userInfo.id}`);
		}
		// clear storage when tab is close, ex: when we close
		// tab Redux will become null hence clear local storage
		if (!loginDetails.userInfo) {
			localStorage.clear();
		}

		localStorage.removeItem('ex-claimrewards');
	}, [loginDetails, history, error]);

	/* onSubmitHandler submits the rewardId and dob of user to the backend  */

	const onSubmitHandler = (e) => {
		e.preventDefault();
		if (dob && rewardId) {
			const userId = rewardId;
			const id = '' + CryptoJS.SHA256(userId);
			dispatch(loginRequest(rewardId, dob, id));
		}

		dispatch(updatedValues(rewardId, dob));
	};

	return (
		<div className="sign-in">
			{loading && <Loader />}
			<FormContainer>
				<img src={logo} alt="Logo" className="hrsc-logo" />
				<h2 className="heading">Please Sign In</h2>
				<p>
					<strong>
						Redemptions become available on the Sunday following the kiosk swipe.
					</strong>
					<br /><br />Please check your voucher for redemption dates.<br />
				</p>
				<Form onSubmit={(e) => onSubmitHandler(e)}>
					<Form.Group controlId="formBasicEmail">
						<Form.Label>Caesars Rewards #</Form.Label>
						<Form.Control
							type="number"
							value={rewardId}
							placeholder="Caesars Rewards"
							onChange={(e) => setRewardId(e.target.value)}
						/>
					</Form.Group>

					<Form.Group controlId="formBasicPassword">
						<Form.Label>Birth Date(MM/DD/YYYY)</Form.Label>
						<InputMask
							className="form-control"
							mask="99/99/9999"
							placeholder="mm/dd/yyyy"
							value={dob}
							onChange={(e) => setDob(e.target.value)}
						/>
					</Form.Group>
					<Button variant="primary" type="submit">
						SIGN IN
					</Button>

				</Form>
				{error && (
					<div className="signIn">
						<div role="alert">{error}</div>
					</div>
				)}
			</FormContainer>
		</div>
	);
};

export default LoginScreen;