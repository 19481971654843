import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Overlay from '../styles/Overlay.css';

const OverLay = ({ show, handleClose, handleLogout }) => {
	let initialMinute = 15;
	let initialSeconds = 0;
	const [minutes, setMinutes] = useState(initialMinute);
	const [seconds, setSeconds] = useState(initialSeconds);

	useEffect(() => {
		let myInterval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}
			if (seconds === 0) {
				if (minutes === 0) {
					clearInterval(myInterval);
				} else {
					setMinutes(minutes - 1);
					setSeconds(59);
				}
			}
		}, 1000);
		return () => {
			clearInterval(myInterval);
		};
	});

	return (
		<>
			<Modal show={show} class={Overlay.modal} onHide={handleClose} closeButton>
				<Modal.Header closeButton>
					<div class={Overlay.header}>Session Information</div>
				</Modal.Header>
				<Modal.Body>
					<div>
						your session will expires with in {minutes} Minutes:
						{seconds < 10 ? `0${seconds}` : seconds} seconds.Would you like to
						continue click
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button class="btn btn-primary" onClick={handleClose}>
						Stay In
					</Button>
					<Button class="btn btn-primary" onClick={handleLogout}>
						Logout
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default OverLay;