import { BACK_OFFICE_ACTIONS } from '../constants/backOfficeActions';

import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
export const sendFileData = (file,e) => async (dispatch) => {

	let formDatas = new FormData(e.target);
	const formData = new FormData();
	for (var [key, value] of formDatas.entries()) { 
		console.log(key, value);
		formData.append(key,value);
	}

	console.log('fd on submit handler', formData);
	console.log('...formdata', formData.get('file'));
	for (var [key, value] of formData.entries()) { 
		console.log(key, value);
	}

	let toastId = toast.loading("Please wait. Processing file.");
	try {

		await axios({
			method: "post",
			url: process.env.REACT_APP_EXPORT_EXCEL_URL,
			data: formDatas,
			headers: { "Content-Type": "multipart/form-data" },
		  })
			.then(function (response) {
			  //handle success
			  toast.dismiss(toastId);
			  toast.success("Excel upload success! Status=" + response.status
			  		+ ", Message=" + response.data.message,
					{progress: 0.99});
			  console.log(response);
			})
			.catch(function (error) {
			  //handle error
			  toast.dismiss(toastId);
			  toast.error('Excel upload failed with error=' + error.response.data.message,
			  	{progress: 0.99});
			  console.log(error.response.data.message);
			});

	} catch (error) {
		//toast.error('Excel upload fail', {duration: 60000});
		toast.dismiss(toastId);
		toast.error('Error on Excel file: ' + error.response.data.message,
			{progress: 0.99});
		console.log('error', error);
	}
};

/* export const downloadReport = () => async () => {

	try {
		axios({
			url: process.env.REACT_APP_DOWNLOAD_EXCEL_URL,
			method: 'GET',
			responseType: 'blob', // important
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'file.csv'); //or any other extension
			document.body.appendChild(link);
			link.click();
		});

	} catch (error) {
		console.log('error', error);
	}
};
 */
export const activateReport = () => async () => {

	try {

		await axios({
			method: "post",
			url: process.env.REACT_APP_DATA_ACTIVATE_URL,
		  })
			.then(function (response) {
			  //handle success
			  toast.success(response.data);
			  console.log(response);
			})
			.catch(function (response) {
			  //handle error
			  toast.error('Data activation failed..!',response)
			  console.log(response);
			});

	} catch (error) {
		toast.error('Data activation failed..!',error);
		console.log('error', error);
	}
};
