import React from 'react';

import { Navbar, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { logOut } from '../actions/userAction';
import { useHistory } from 'react-router-dom';
import logo from '../static/HRSC-Logo-2022-1280.png';

const Header = ({ id }) => {
	const loginDetails = useSelector((state) => state.loginDetails);

	const { loading, userInfo } = loginDetails;

	const history = useHistory();

	const dispatch = useDispatch();

	const logOutHandler = () => {
		if (id) {
			localStorage.removeItem('reward-' + userInfo.id);
			localStorage.removeItem('orders-' + userInfo.id);
			localStorage.removeItem('ex-' + id);
		}

		dispatch(logOut());
		history.push('/');
	};
	return (
		<>
			<Navbar>
				<Navbar.Collapse className="justify-content-end">
					<Navbar.Text>
						<Button variant="link" onClick={logOutHandler}>
							Sign Out
						</Button>
					</Navbar.Text>
				</Navbar.Collapse>
			</Navbar>
			<img src={logo} alt="Logo" className="hrsc-logo" />
		</>
	);
};

export default Header;
