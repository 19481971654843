import React from 'react';
import { logOut } from '../actions/userAction';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const OrderTable = ({ orders }) => {
	const history = useHistory();

	const dispatch = useDispatch();

	const redirectToHomePage = () => {
		dispatch(logOut());
		history.push('/');
	};
	return (
		<div>
			<h5>You have successfully redeemed your gift cards!</h5>
			<p>
				Your order consists of multiple gift cards listed in the table below.
			</p>
			<br />
			<table class="table">
				<thead class="tablehead">
					<tr>
						<th scope="col">Amount</th>
						<th scope="col">Brand</th>
						<th scope="col">Order ID</th>
						<th scope="col">Link</th>
					</tr>
				</thead>
				<tbody>
					{orders?.map((order) => (
						<tr key={order.OrderId}>
							<td>${order.InitialBalance}</td>
							<td>{order.Brand}</td>
							<td>{order.OrderId}</td>
							
							{order.CertificateLink === 'PENDING' ? 
								(
									<td>PENDING</td>
								) :
								(
									<td>
								
										<a
											href={order.CertificateLink}
											// onClick={redirectToHomePage}
											rel="noreferrer"
											target="_blank"
											className="card-link"
										>
											{order.CertificateLink}
										</a>
									</td>
								)}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default OrderTable;