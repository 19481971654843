import React from 'react';

const Footer = () => {
	return (
		<>
			<div className='footermsg'>
				For questions please contact <a href="mailto:cs@sigmarsolutions.com?subject=HRSC Gift Cards">cs@sigmarsolutions.com</a>
			</div>
		</>
	);
};

export default Footer;
