import React from 'react';
import { Alert } from 'react-bootstrap';

const SystemError = ({ variant, children }) => {
	return <Alert variant={variant}>{children}</Alert>;
};

SystemError.defaultProps = {
	variant: 'info'
};

export default SystemError;
