import axios from 'axios';
import moment from 'moment';

import {
	USER_REGISTER_REQUEST,
	USER_REGISTER_SUCCESS,
	USER_REGISTER_FAIL,
	USER_REGISTERED_VALUES,
	USER_LOGOUT,
	UPDATE_STATE,
	EMPTY_UPDATED_STATE,
} from '../constants/authActions';

export const loginRequest =
	(rewardsId, dob, id) => async (dispatch, getState) => {
		try {
			dispatch({
				type: USER_REGISTER_REQUEST,
			});

			const { data } = await axios({
				url: process.env.REACT_APP_LOGIN_URL,
				method: 'POST',
				data: {
					rewardsId: parseInt(rewardsId),
					dob: moment(dob).format('MM/DD/YYYY'),
				},
			});

			dispatch({
				type: USER_REGISTER_SUCCESS,
				payload: { ...data, id },
			});
		} catch (error) {
			dispatch({
				type: USER_REGISTER_FAIL,
				payload: error.response.data,
			});
		}
	};
export const updatedValues = (caesarsReward, password) => async (dispatch) => {
	const data = {
		CaesarsReward: caesarsReward,
		Password: password,
	};

	try {
		dispatch({
			type: USER_REGISTERED_VALUES,
			payload: data,
		});
	} catch (error) {}
};

export const logOut = () => (dispatch, getState) => {
	dispatch({
		type: USER_LOGOUT,
	});
};

export const updateState = (userData) => (dispatch) => {
	dispatch({
		type: UPDATE_STATE,
		payload: userData,
	});
};
export const emptyUpdateState = () => (dispatch) => {
	dispatch({
		type: EMPTY_UPDATED_STATE,
	});
};
