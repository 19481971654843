import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

import { select, Form, Button } from 'react-bootstrap';
import { sendFileData,activateReport } from '../actions/backOfficeAction';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { ToastContainer, toast } from 'react-toastify';



const BackOfficeScreen = () => {
	const [selectedFile, setSelectedFile] = useState();

	const dispatch = useDispatch();
	const onFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const submitFileHandler = (e) => {
		console.log('event value ', e.target.value);
		e.preventDefault();

		dispatch(sendFileData(selectedFile,e));
	};

	// const downloadReportHandler = (e) => {
	// 	console.log('event value ', e.target.value);
	// 	e.preventDefault();

	// 	dispatch(downloadReport());
	// };

	const activateHandler = (e) => {
		console.log('event value ', e.target.value);
		e.preventDefault();

		dispatch(activateReport());
	};

	return (
		<><ToastContainer /><Tabs defaultActiveKey="upload" id="uncontrolled-tab-example" className="mb-3">
			<Tab eventKey="upload" title="Upload" >
				<form name="myForm" encType="multipart/form-data" action="" onSubmit={submitFileHandler}>
					<h2>Back Office Excel Upload</h2>
					<div class="form-group">
						<select name="Activate The list when select true" class="custom-select">
							<option value="false" selected>Select true if need to Activate the Giftcards list</option>
							<option value="true">true</option>
							<option value="false">false</option>
						</select>
					</div>
					<div class="form-group">
						<select name="Select true if this is Bulk card file" class="custom-select">
							<option value="false" selected>Select true if this is Bulk card file</option>
							<option value="true">true</option>
							<option value="false">false</option>
						</select>
					</div>

					<div class="form-group">
						<select name="Validate the Data before Upload" class="custom-select">
							<option value="false" selected>Select true if need to validate the Data before Upload</option>
							<option value="true">true</option>
							<option value="false">false</option>
						</select>
					</div>
					<div class="form-group">
						<input
							type="file"
							name="file"
							class="form-control-file"
							id="ExcelFormControlFile"
							onChange={(e) => onFileChange(e)}
						></input>
					</div>
					<div>
						<Button variant="primary" type="submit" className="upload-button">
							Upload
						</Button>
						<Button variant="secondary" type="submit" onClick={activateHandler}> 
							Activate
						</Button>
					</div>
					<div>
						
					</div>
				</form>
			</Tab>
			{/* <Tab eventKey="download" title="Download">
				<Button variant="primary" onClick={downloadReportHandler}>
					Generate Report
				</Button>
			</Tab> */}
		</Tabs></>
		
	);
};

export default BackOfficeScreen;
